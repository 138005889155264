import type { Ref } from 'vue'

export function useCarouselScroll(el: Ref<HTMLElement>) {
  const x = shallowRef<number>(0)

  function onMouseDown(e: PointerEvent) {
    el.value.style.scrollSnapType = 'none'
    el.value.style.scrollBehavior = 'auto'

    x.value = e.pageX

    window.addEventListener('mousemove', onMouseMove, { passive: false })
    window.addEventListener('mouseup', onMouseUp)
  }

  function onMouseUp() {
    el.value.style.removeProperty('scroll-behavior')
    el.value.style.removeProperty('scroll-snap-type')
    el.value.style.removeProperty('pointer-events')

    window.removeEventListener('mousemove', onMouseMove)
    window.removeEventListener('mouseup', onMouseUp)
  }

  function onMouseMove(e: PointerEvent) {
    e.preventDefault()
    el.value.style.pointerEvents = 'none'

    const delta = e.pageX - x.value

    x.value = e.pageX

    el.value.scrollBy(-delta, 0)
  }

  onMounted(() => {
    if (!el.value) {
      return
    }

    el.value.addEventListener('mousedown', onMouseDown, { passive: true })
  })

  onUnmounted(() => {
    if (!el.value) {
      return
    }

    el.value.removeEventListener('mousedown', onMouseDown)
  })
}
